<template>
  <ckeditor
    :editor="editor"
    v-model="editorData"
    :config="editorConfig"
    @ready="onReady"
  />
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2'
import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor'
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import AutoImagePlugin from '@ckeditor/ckeditor5-image/src/autoimage'
import AutoLinkPlugin from '@ckeditor/ckeditor5-link/src/autolink'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import FindAndReplacePlugin from '@ckeditor/ckeditor5-find-and-replace/src/findandreplace'
import FontBackgroundColorPlugin from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor'
import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor'
import FontFamilyPlugin from '@ckeditor/ckeditor5-font/src/fontfamily'
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import HighlightPlugin from '@ckeditor/ckeditor5-highlight/src/highlight'
import HorizontalLinePlugin from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageInsertPlugin from '@ckeditor/ckeditor5-image/src/imageinsert'
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import IndentPlugin from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlockPlugin from '@ckeditor/ckeditor5-indent/src/indentblock'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import LinkImagePlugin from '@ckeditor/ckeditor5-link/src/linkimage'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import ListPropertiesPlugin from '@ckeditor/ckeditor5-list/src/listproperties'
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import RemoveFormatPlugin from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import SpecialCharactersPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import SpecialCharactersArrowsPlugin from '@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows'
import StrikethroughPlugin from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import SubscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import SuperscriptPlugin from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import TablePlugin from '@ckeditor/ckeditor5-table/src/table'
import TableCaptionPlugin from '@ckeditor/ckeditor5-table/src/tablecaption'
import TableCellPropertiesPlugin from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableColumnResizePlugin from '@ckeditor/ckeditor5-table/src/tablecolumnresize'
import TablePropertiesPlugin from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TextTransformationPlugin from '@ckeditor/ckeditor5-typing/src/texttransformation'
import TodoListPlugin from '@ckeditor/ckeditor5-list/src/todolist'
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline'
import GeneralHtmlSupportPlugin from '@ckeditor/ckeditor5-html-support/src/generalhtmlsupport'

export default {
  name: 'FieldEditor',

  components: {
    ckeditor: CKEditor.component
  },

  props: {
    value: {},
    toolbarRef: {},
    items: {
      type: Array,
      default: () => getItems()
    }
  },

  data () {
    return {
      editor: DecoupledEditor,
      editorData: String(this.value || '').trim(),
      editorConfig: {
        plugins: [
          AlignmentPlugin,
          AutoformatPlugin,
          AutoImagePlugin,
          AutoLinkPlugin,
          BlockQuotePlugin,
          BoldPlugin,
          PageBreakPlugin,
          EssentialsPlugin,
          FindAndReplacePlugin,
          FontBackgroundColorPlugin,
          FontColorPlugin,
          FontFamilyPlugin,
          FontSizePlugin,
          HeadingPlugin,
          HighlightPlugin,
          HorizontalLinePlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageInsertPlugin,
          ImageResizePlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          IndentPlugin,
          IndentBlockPlugin,
          ItalicPlugin,
          LinkPlugin,
          LinkImagePlugin,
          ListPlugin,
          ListPropertiesPlugin,
          MediaEmbedPlugin,
          ParagraphPlugin,
          PasteFromOfficePlugin,
          RemoveFormatPlugin,
          // SimpleUploadAdapterPlugin,
          SpecialCharactersPlugin,
          SpecialCharactersArrowsPlugin,
          StrikethroughPlugin,
          SubscriptPlugin,
          SuperscriptPlugin,
          TablePlugin,
          TableCaptionPlugin,
          TableCellPropertiesPlugin,
          TableColumnResizePlugin,
          TablePropertiesPlugin,
          TableToolbarPlugin,
          TextTransformationPlugin,
          TodoListPlugin,
          UnderlinePlugin,
          GeneralHtmlSupportPlugin
        ],
        toolbar: {
          items: this.items,
          shouldNotGroupWhenFull: true
        },
        language: 'pt-br',

        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ]
        },

        image: {
          styles: [
            'alignCenter',
            'alignLeft',
            'alignRight'
          ],

          resizeOptions: [{
            name: 'resizeImage:original',
            label: 'Original',
            value: null
          }, {
            name: 'resizeImage:50',
            label: '50%',
            value: '50'
          }, {
            name: 'resizeImage:75',
            label: '75%',
            value: '75'
          }],

          toolbar: [
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'resizeImage',
            '|',
            'linkImage'
          ],
          insert: {
            integrations: [
              'insertImageViaUrl'
            ]
          }
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        htmlEmbed: {
          showPreviews: true
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties',
            'toggleTableCaption'
          ]
        },

        placeholder: 'Escreva ou cole o conteúdo aqui!',
        mediaEmbed: {
          extraProviders: [{
            name: 'extraProvider',
            url: /^api\.dmcvivo\.udv\.org\.br\/v1\/audios\/download\?(\w+)=(\w+)/,
            html: (match) => `<audio style="position: relative" src="${match.input}" controls preload></audio>`
          }]
        }
      }
    }
  },

  methods: {
    onReady (editor) {
      if (!this.toolbarRef) return
      const toolbarRef = this.toolbarRef
      while (toolbarRef.childNodes.length) toolbarRef.removeChild(toolbarRef.firstElementChild)

      this.$nextTick(() => {
        toolbarRef.appendChild(editor.ui.view.toolbar.element)
        document.querySelector('.ck-toolbar').classList.add('ck-reset_all')
      })
    }
  },

  watch: {
    editorData (content) {
      const newContent = String(content || '').trim()
      const oldContent = String(this.value || '').trim()
      if (newContent !== oldContent) this.$emit('input', newContent)
    },

    value (value) {
      const newContent = String(value || '').trim()
      const oldContent = String(this.editorData || '').trim()
      if (newContent !== oldContent) this.editorData = newContent
    }
  }
}

const getItems = () => [
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  '|',
  'fontColor',
  'fontBackgroundColor',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'highlight',
  '|',
  'alignment',
  'numberedList',
  'bulletedList',
  'outdent',
  'indent',
  'todoList',
  '|',
  'link',
  'blockQuote',
  'imageUpload',
  'insertTable',
  'mediaEmbed',
  '|',
  'findAndReplace',
  'subscript',
  'superscript',
  'imageInsert',
  'removeFormat',
  'undo',
  'redo'
]
</script>

<style lang="sass">
  .ck-editor__editable
    min-height: 35vh
</style>
